<template>
    <b-modal :id="$route.name + 'CreateModal'" title="Добавить" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="getData()">
        <b-row>
            <b-col cols="12">
                <label for="">Подразделение:</label>
                <v-select
                :options="divisions"
                label="name"
                v-model="payment.division_id"
                :reduce="option => option.id"
                >
                </v-select>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col cols="8">
                <label for="">Тип документа:<i class="required">*</i></label>
                <v-select
                :options="payment_types"
                label="name"
                :reduce="option => option.id"
                v-model="payment.payment_type_id"
                >
                </v-select>
            </b-col>
            <b-col cols="4">
                <label for="">Сумма:<i class="required">*</i></label>
                <b-input v-model="payment.amount" type="number"></b-input>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col cols="3">
                <label for="">Логин:<i class="required">*</i></label>
                <b-input v-mask="'######'" @input="getDriverInfo()" v-model="payment.login"></b-input>
            </b-col>
            <b-col cols="6">
                <label for="">ФИО исполнителя:</label>
                <b-input v-model="driver_info"  disabled></b-input>
            </b-col>
            <b-col cols="3">
                <label>Баланс:</label>
                <b-input disabled v-mask="'######'" v-model="driver_balance"></b-input>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col>
                <label for="">Комментарий: <i class="required">*</i></label>
                <b-form-textarea v-model="payment.comment" placeholder="Введите текст..."></b-form-textarea>
            </b-col>
        </b-row>
        <hr>    
        <b-row>
            <b-col class="d-flex justify-content-end">
                <b-button @click="addPayment()" variant="primary" class="mr-1">
                    Ок
                </b-button>
                <b-button @click="close()" variant="danger">
                    Отмена
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { mask } from "vue-the-mask"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    directives: { mask },
    components: {
        vSelect,
        ToastificationContent
    },
    data() {
        return {
            divisions: [],
            payment_types: [],
            driver_info: null,
            driver_balance: null,
            payment: {
                division_id: 1,
                payment_type_id: null,
                amount: null,
                comment : null,
                login: null
            }
        }
    },
    methods: {
        getData(){
            this.$http
            .get('billing/payment-document/data')
            .then(res => {
                this.divisions = res.data.division
                this.payment_types = res.data.payment_types
            })
            .catch(err => {
            })
        },
        addPayment(){
            this.$http
            .post('billing/payment-document/payment-make', this.payment)
            .then(res => {
                this.close()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Баланс пополнен`,
                        },
                    })
                    this.$emit('refresh')
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err,
                    },
                })
            })
        }, 
        close() {
            this.payment = {
                division_id: 1,
                payment_type_id: null,
                amount: null,
                comment : null,
                login: null
            }
            this.driver_info = null
            this.driver_balance = null
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
        getDriverInfo(){
            if (this.payment.login.length > 5) {
                let login = {
                    login: this.payment.login
                }
                this.$http
                .post('drivers/search-by-login', login)
                .then(res => {
                    this.driver_info = res.data.first_name + ' ' + res.data.last_name + ' ' + (res.data.patronymic ? res.data.patronymic : '')
                    this.driver_balance = res.data.balance.balance
                })
            }
        }
    },
}

</script>

<style scoped>
.required {
    color: red;
}
</style>